const en = {

    // Error messages
    NETWORK_ERROR:"Network error",
    GET_USER:"Error fetching user data",
    GET_LIST:"Error retrieving entries",
    POST_LEAD:"Error creating lead",
    PATCH_ITEM:"Error editing entry",
    SEARCH:"Error on search",
    GET_STATUS:"Error fetching lead status from CRM",
    GET_OPTIONS:"Error getting list options from CRM",
    GET_ONEITEM:"Error getting item",
    GET_CONFIG:"Error getting CRM configurations",
    POST_CALL:"Error creating task",
    GET_RELATIONSHIP:"Error getting item relationship",
    POST_USER:"Error creating user",
    IS_SUPERVISOR:"Error getting user's permissions",
    DELETE_ITEM:"Error deleting item",

    loginUrlExample: "Company",
    loginUsername: "Username",
    loginPassword: "Password",
    loginButton: "Login",
    loginErrorField: "Error in field",
    loginErrorMessageCompany: "Input a valid company name",
    loginErrorMessageCredentials: "Incorrect username or password",
    loginErrorMessageOr: "or",

    leadsTitle: "Leads",
    leadTitle: "Lead",
    createLeadTitle: "Add Lead",
    leadsGeneralTitle: "General",
    leadsHistoryTitle: "History",
    leadsFormFullName:"Full Name",
    leadsFormDetail: "Detail Lead",
    leadsFormPhone1: "Phone 1",
    leadsFormPhone2: "Phone 2",
    leadsFormEmail: "Email",
    leadsFormStatus: "Status",
    leadsFormChooseStatus: "Select status", // reemplazar "Select Item"
    leadsFormSubstatus: "Substatus",
    leadsFormProduct: "Product",
    leadsFormChooseProduct: "Select product", // reemplazar "Select Item"
    leadsFormDescription: "Description",
    leadsFormHomeAddress: "Street address",
    leadsFormProvince:"Region",
    leadsFormCity: "City",
    leadsFormCancelBotton: "Cancel",
    leadsFormSaveBotton: "Save",
    leadsEmptyList: "No leads in status",
    leadFormAddTitle: "Add Lead",
    leadHistoryTitle:"Tasks",
    leadHistoryPlanned:"Planned",
    leadHistoryHeld:"Held",
    leadHistoryEmpty:"No tasks assigned to this Lead",
    leadsFormRequiredFields:"Insert all required fields",
    searchInputLeads: "Search for a Lead",

    agendaScreen: "Coming Soon",
    agendaTitle:"Agenda",
    taskTitle:"Add Task",

    agendaFormAddTitle: "Add Task",
    agendaEmptyList: "No Pending Tasks",
    agendaListOverdue: "Overdue",
    agendaListToday:"Today",
    agendaListTomorrow:"Tomorrow",
    agendaListOf:"of",

    agendaListCall:"Call",
    agendaListMessage:"Message",

    agendaFormTask:"Task",
    agendaFormDateTime:"Date & time",
    agendaFormAssignedTo:"Assigned to",
    agendaFormSearchPlaceholder:"Search for a Lead",

    agendaAlertTaskDoneConfirmTitle:"Mark task as done?",
    agendaAlertTaskUnDoneConfirmTitle:"Uncheck task?",

    contactsTitle: "Sales",
    contactTitle: "Sales",
    contactsFormDetail: "Detail Sale",
    contactsFormStatus: "Sale",
    contactsFormPhone1: "Phone 1",
    contactsFormPhone2: "Phone 2",
    contactsFormEmail: "Email",
    contactsFormProduct: "Product",
    contactsFormChooseProduct: "Select product", // reemplazar "Select Item"
    contactsFormStreetAddress: "Street address",
    contactsFormCity: "City",
    contactsFormChooseCity: "Select City", // reemplazar "Select Item"
    contactsFormProvince: "State / Province",
    contactsFormChooseProvince: "Select State/Province", // reemplazar "Select Item"
    contactsFormCountry: "Country",
    contactsFormChooseCountry: "Select Country", // reemplazar "Select Item"
    contactsFormPaymentMethod: "Payment method",
    contactsFormChoosePaymentMethod: "Select Payment Method", // reemplazar "Select Item"
    contactsFormCardNumber: "Card number",
    contactsFormCardholderName: "Cardholder Name",
    contactsFormPlaceholderCardholderName: "As shown on card",
    contactsFormCardExpiration: "Expiration",
    contactsFormCardSecurityCode: "Security code",
    contactsFormCancelBotton: "Cancel",
    contactsFormSaveBotton: "Save",
    contactsEmptyList: "No Sales",

    alertFormEditTitle:"Discard changes?",
    alertFormEditAccept:"YES",
    alertFormEditCancel:"NO",
    alertFormRequired:"Fill all required fields",

    searchInputContacts: "Search for a sale",
    searchTitle:"Search",

    settingsTitle: "Settings",
    settingsLanguagePopupTitle:"Language",
    settingsLanguage: "Select your Language",
    settingsLogout: "Logout",
    settingsUser: "User",
    settingsUpdate: "Update Settings",
    settingsCreateUser: "Create user",
    settingsViewUsers: "View Users",

    createUserTitle: "Crear Usuario",
    userFormUserName: "Username",
    userFormFirstName: "Name",
    userFormPhone: "Phone Number",
    userFormPassword: "Password",
    userFormEmail:"E-mail",

    userListTitle:"Users",
    usersEmptyList: "No users",

    userAlertDelete: "Delete user?",

    menuAccept:"Accept",

    Month1:"January",
    Month2:"February",
    Month3:"March",
    Month4:"April",
    Month5:"May",
    Month6:"June",
    Month7:"July",
    Month8:"August",
    Month9:"September",
    Month10:"October",
    Month11:"November",
    Month12:"December",
};

const es = {

    // Error messages
    NETWORK_ERROR:"Error de conexion",
    GET_USER:"Error al buscar datos del usuario",
    GET_LIST:"Error al buscar datos",
    POST_LEAD:"Error en la creacion del dato",
    PATCH_ITEM:"Error al editar dato",
    SEARCH:"Error de busqueda",
    GET_STATUS:"Error buscando estados del CRM",
    GET_OPTIONS:"Error al buscar listas de opciones del CRM",
    GET_ONEITEM:"Error al consultar el item",
    GET_CONFIG:"Error al consultar las configuraciones del CRM",
    POST_CALL:"Error al crear la tarea",
    GET_RELATIONSHIP:"Error al consultar vinculos del item",
    POST_USER:"Error al crear usuario",
    IS_SUPERVISOR:"Error al consultar permisos del usuario",
    DELETE_ITEM:"Error al eliminar item",

    loginUrlExample: "Compañía",
    loginUsername: "Usuario",
    loginPassword: "Contraseña",
    loginButton: "Iniciar sesión",
    loginErrorField: "Error en el campo",
    loginErrorMessageCompany: "Ingrese una compañia valida",
    loginErrorMessageCredentials: "Nombre de usario o contraseña incorrectas",
    loginErrorMessageOr: "o",

    leadsTitle: "Leads",
    leadTitle: "Lead",
    createLeadTitle: "Agregar Lead",


    leadsGeneralTitle: "General",
    leadsHistoryTitle: "Historial",
    leadsFormFullName:"Nombre y Apellido",
    leadsFormDetail: "Detalle Lead",
    leadsFormPhone1: "Teléfono 1",
    leadsFormPhone2: "Teléfono 2",
    leadsFormEmail: "Email",
    leadsFormStatus: "Estado",
    leadsFormChooseStatus: "Seleccione un estado",  // reemplazar "Select Item"
    leadsFormSubstatus: "Subestado",
    leadsFormProduct: "Producto",
    leadsFormChooseProduct: "Seleccione un producto",  // reemplazar "Select Item"
    leadsFormDescription: "Descripción",
    leadsFormHomeAddress: "Dirección",
    leadsFormProvince:"Departamento",
    leadsFormCity: "Ciudad",
    leadsFormCancelBotton: "Cancelar",
    leadsFormSaveBotton: "Guardar",
    leadsEmptyList: "No hay leads en el estado",
    leadFormAddTitle: "Agregar Lead",
    leadHistoryTitle:"Tareas",
    leadHistoryPlanned:"Planificadas",
    leadHistoryHeld:"Realizadas",
    leadHistoryEmpty:"No hay Tareas asignadas a este Lead",
    leadsFormRequiredFields:"Ingrese los datos requeridos",

    searchInputLeads: "Buscar un lead",

    agendaScreen: "Próximamente",
    agendaTitle:"Agenda",
    taskTitle:"Crear Tarea",

    agendaFormAddTitle: "Crear Tarea",
    agendaEmptyList: "No hay tareas",
    agendaListOverdue: "Vencidas",
    agendaListToday:"Hoy",
    agendaListTomorrow:"Mañana",
    agendaListOf:"de",

    agendaListCall:"Llamada",
    agendaListMessage:"Mensaje",

    agendaFormTask:"Tarea",
    agendaFormDateTime:"Dia y Horario",
    agendaFormAssignedTo:"Asignada a",
    agendaFormSearchPlaceholder:"Busque un Lead",

    agendaAlertTaskDoneConfirmTitle:"¿Marcar tarea como realizada?",
    agendaAlertTaskUnDoneConfirmTitle:"¿Desmarcar tarea?",

    contactsTitle: "Ventas",
    contactTitle: "Ventas",
    contactsFormDetail: "Detalle Venta",
    contactsFormStatus: "Venta",
    contactsFormPhone1: "Teléfono 1",
    contactsFormPhone2: "Teléfono 2",
    contactsFormEmail: "Email",
    contactsFormProduct: "Producto",
    contactsFormChooseProduct: "Seleccione un producto",  // reemplazar "Select Item"
    contactsFormStreetAddress: "Calle y numeración",
    contactsFormCity: "Ciudad",
    contactsFormChooseCity: "Seleccione una Ciudad", // reemplazar "Select Item"
    contactsFormProvince: "Provincia / Estado",
    contactsFormChooseProvince: "Seleccione una Provincia / Estado", // reemplazar "Select Item"
    contactsFormCountry: "País",
    contactsFormChooseCountry: "Seleccione un país", // reemplazar "Select Item"
    contactsFormPaymentMethod: "Método de pago",
    contactsFormChoosePaymentMethod: "Seleccione un método de pago", // reemplazar "Select Item"
    contactsFormCardNumber: "Número de tarjeta",
    contactsFormCardholderName: "Nombre del titular de la tarjeta",
    contactsFormPlaceholderCardholderName: "Como figura en la tarjeta",
    contactsFormCardExpiration: "Vencimiento",
    contactsFormCardSecurityCode: "Código de seguridad",
    contactsFormCancelBotton: "Cancelar",
    contactsFormSaveBotton: "Guardar",
    contactsEmptyList: "No hay ventas",

    alertFormEditTitle:"¿Descartar cambios?",
    alertFormEditAccept:"SI",
    alertFormEditCancel:"NO",
    alertFormRequired:"Llene los datos obligatorios",

    searchInputContacts: "Buscar una venta",
    searchTitle:"Busqueda",

    settingsTitle: "Configuración",
    settingsLanguagePopupTitle:"Idioma",
    settingsLanguage: "Selecciona tu idioma",
    settingsLogout: "Cerrar Sesión",
    settingsUser: "Usuario",
    settingsUpdate: "Actualizar Configuración",
    settingsCreateUser: "Crear usuario",
    settingsViewUsers: "Ver Usuarios",

    createUserTitle: "Crear Usuario",
    userFormUserName: "Nombre de Usuario",
    userFormFirstName: "Nombre",
    userFormPhone: "Telefono",
    userFormPassword: "Contraseña",
    userFormEmail:"E-mail",

    userListTitle:"Usuarios",
    usersEmptyList: "No hay usuarios",

    userAlertDelete: "Eliminar usuario?",

    menuAccept:"Aceptar",

    Month1:"Enero",
    Month2:"Febrero",
    Month3:"Marzo",
    Month4:"Abril",
    Month5:"Mayo",
    Month6:"Junio",
    Month7:"Julio",
    Month8:"Agosto",
    Month9:"Septiembre",
    Month10:"Octubre",
    Month11:"Noviembre",
    Month12:"Diciembre",

};

const ptBR = {

    // Error messages
    NETWORK_ERROR:"Erro de conexão", //verificar traduccion
    GET_USER:"Erro ao pesquisar dados do usuário", //verificar traduccion
    GET_LIST:"Erro ao pesquisar dados", //verificar traduccion
    POST_LEAD:"Erro na criação de dados", //verificar traduccion
    PATCH_ITEM:"Erro ao editar dados", //verificar traduccion
    SEARCH:"erro de pesquisa", //verificar traduccion
    GET_STATUS:"Erro ao pesquisar status de CRM", //verificar traduccion
    GET_OPTIONS:"Erro ao pesquisar listas de opções de CRM", //verificar traduccion
    GET_ONEITEM:"Erro ao consultar o item", //verificar traduccion
    GET_CONFIG:"Erro ao consultar configurações de CRM", //verificar traduccion
    POST_CALL:"Erro ao criar tarefa", //verificar traduccion
    GET_RELATIONSHIP:"Erro ao consultar links de itens", //verificar traduccion
    POST_USER:"Erro ao criar usuário", //verificar traduccion
    IS_SUPERVISOR:"Erro ao consultar permissões do usuário", //verificar traduccion
    DELETE_ITEM:"Erro ao remover item", //verificar traduccion

	loginUrlExample: "Negócio",
	loginUsername: "Usuário",
	loginPassword: "Senha",
	loginButton: "Iniciar Sessão",
    loginErrorField: "Erro no campo",
    loginErrorMessageCompany: "Inserir um negócio válido",
    loginErrorMessageCredentials: "Nome de usuário ou senha incorretos",
    loginErrorMessageOr: "ou",

    leadsTitle: "Leads",
    leadTitle: "Lead",
    createLeadTitle: "Adicionar lead",


    leadsGeneralTitle: "Geral", //verificar traduccion
    leadsHistoryTitle: "História",//verificar traduccion
	leadsFormFullName: "Nome Completo",
	leadsFormDetail: "Detalhe",
	leadsFormPhone1: "Telefone 1",
	leadsFormPhone2: "Telefone 2",
	leadsFormEmail: "E-mail",
	leadsFormStatus: "Estado",
	leadsFormChooseStatus: "Escolha um estado",
    leadsFormSubstatus: "Subestado",
	leadsFormProduct: "Produto",
	leadsFormChooseProduct: "Escolha um produto",
	leadsFormDescription: "Descrição",
	leadsFormHomeAddress: "Endereço",
    leadsFormProvince:"Estado",
    leadsFormCity: "Cidade",
	leadsFormCancelBotton: "Cancelar",
	leadsFormSaveBotton: "Salvar",
	leadsEmptyList: "Não há Leads",
	leadFormAddTitle: "Adicionar lead",
    leadHistoryTitle:"Tarefas",
    leadHistoryPlanned:"Planeado",//verificar traduccion
    leadHistoryHeld:"Realizada",//verificar traduccion
    leadHistoryEmpty:"Nenhuma tarefa atribuída a este Lead", //verificar traduccion
    leadsFormRequiredFields:"Insira os dados necessários", //verificar traduccion

    searchInputLeads: "Procurar um lead",

	agendaScreen: "Em breve",
    agendaTitle:"Agenda",
    taskTitle:"Criar uma tarefa",


	agendaFormAddTitle: "Criar uma tarefa",
	agendaEmptyList: "Não há tarefas",
	agendaListOverdue: "Vencidas",
	agendaListToday: "Hoje",
	agendaListTomorrow: "Amanhã",
	agendaListOf: "de",

    agendaListCall:"Chamar", //verificar traduccion
    agendaListMessage:"Mensagem", //verificar traduccion

	agendaFormTask: "Tarefa",
	agendaFormDateTime: "Dia e horário",
	agendaFormAssignedTo: "Alocar a",
	agendaFormSearchPlaceholder: "Procure um Lead",

    agendaAlertTaskDoneConfirmTitle:"Marcar tarefa como concluída?", //verificar traduccion
    agendaAlertTaskUnDoneConfirmTitle:"¿Desmarcar tarefa?", //verificar traduccion

    contactsTitle: "Vendas",
    contactTitle: "Vendas",
    
	contactsFormDetail: "Detalhe da venda",
	contactsFormStatus: "Venda",
	contactsFormPhone1: "Telefone 1",
	contactsFormPhone2: "Telefone 2",
	contactsFormEmail: "E-mail",
	contactsFormProduct: "Produto",
	contactsFormChooseProduct: "Escolha um produto",
	contactsFormStreetAddress: "Rua e numeração",
	contactsFormCity: "Cidade",
	contactsFormChooseCity: "Escolha uma cidade",
	contactsFormProvince: "Prefeitura / Estado",
	contactsFormChooseProvince: "Escolha uma Prefeitura / Estado",
	contactsFormCountry: "País",
	contactsFormChooseCountry: "Escolha um país",
	contactsFormPaymentMethod: "Método de pagamento",
	contactsFormChoosePaymentMethod: "Escolha um método de pagamento",
	contactsFormCardNumber: "Número de cartão",
	contactsFormCardholderName: "Nome do titular de o cartão",
	contactsFormPlaceholderCardholderName: "Digite o nome do titular do cartão",
	contactsFormCardExpiration: "Vencimiento",
	contactsFormCardSecurityCode: "Código de segurança",
	contactsFormCancelBotton: "Cancelar",
	contactsFormSaveBotton: "Salvar",
	contactsEmptyList: "Não há vendas",

    alertFormEditTitle: "Descartar alterações?",
	alertFormEditAccept: "SIM",
	alertFormEditCancel: "NÃO",
	alertFormRequired: "Preencha os dados solicitados.",

    searchInputContacts: "Procurar uma venda",
    searchTitle:"Busca",

    settingsTitle: "Configuração",
	settingsLanguagePopupTitle: "Língua",
	settingsLanguage: "Escolha sua língua",
	settingsLogout: "Terminar sessão",
	settingsUser: "Usuário",
    settingsUpdate: "Atualizar configurações", //verificar traduccion
    settingsCreateUser: "Crear usuário", //verificar traduccion
    settingsViewUsers: "Exibir usuários", //verificar traduccion

    createUserTitle: "Crear Usuario", //verificar traduccion
    userFormUserName: "Nombre de Usuario", //verificar traduccion
    userFormFirstName: "Nombre", //verificar traduccion
    userFormPhone: "Telefono", //verificar traduccion
    userFormPassword: "Contraseña", //verificar traduccion
    userFormEmail:"E-mail",

    userListTitle:"Usuários", //verificar traduccion
    usersEmptyList: "Não há usuários", //verificar traduccion

    userAlertDelete: "Remover usuário?", //verificar traduccion

	menuAccept: "Aceitar",

	Month1: "Janeiro",
	Month2: "Fevereiro",
	Month3: "Março",
	Month4: "Abril",
	Month5: "Maio",
	Month6: "Junho",
	Month7: "Julho",
	Month8: "Agosto",
	Month9: "Setembro",
	Month10: "Outubro",
	Month11: "Novembro",
	Month12: "Dezembro",
};

export { en, es, ptBR };
