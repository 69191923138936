import LocationSearch from './LocationSearch';
import '../../assets/css/components/detail_view/AddressInput.css';


const AddressInput = (props) => {
  const {
    label,
    addressField,
    streetNumberField,
    streetNameField,
    cityField,
    regionField,
    stateField,
    countryField,
    latitudeField,
    longitudeField,
    value,
    style = {},
    onChange,
    onChangeSelect,
    onFocus,
  } = props;

  return (
    <div>
      {!!label && <p className="input-label"> {label} </p>}
      <LocationSearch
        addressField={addressField}
        streetNumberField={streetNumberField}
        streetNameField={streetNameField}
        cityField={cityField}
        regionField={regionField}
        stateField={stateField}
        countryField={countryField}
        latitudeField={latitudeField}
        longitudeField={longitudeField}
        onFocus={onFocus}
        onChange={onChange}
        onChangeSelect={onChangeSelect}
        value={value || ''}
        style={style}
      />

    </div>
  );
};

export default AddressInput;
