import { useForm } from "../hooks/useForm";
import { Link, useLocation } from "react-router-dom";
import { getOneitem, patchItem } from "../services/Api";
import { useState, useEffect } from "react";
import { Spinner } from "react-activity";
import GLOBAL from '../services/global.js'
import React from "react";

import Title from "../components/detail_view/Title.js"
import Separator from "../components/detail_view/Separator.js"
import Input from "../components/detail_view/Input.js"
import Select from "../components/detail_view/Select.js"
import AddTaskButton from "../components/detail_view/AddTaskButton.js"
import AddressInput from "../components/detail_view/AddressInput.js"

import Historial from "../components/Historial.js"

import i18n from "i18n-js"

import "../assets/css/pages/OneLead.css";
import "react-activity/dist/library.css";


const OneLead = () => {

  const [lead, setLead] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusObject, setStatusObject] = useState({});
  const [optionLists, setOptionLists] = useState({});

  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [config, setConfig] = useState({});
  const [fields, setFields] = useState([]);
  const [formValues, handleInputChange, reset, setForm, handleManyChange] = useForm({});

  var location = useLocation().pathname.split("/")
  const top_selected = location.pop()
  const id = location.pop()

  const labelMaker = (x, y) => {
    //x = x == '' ? "None" : x
    return {label:x, value:y}
  }
  // crea las opciones para el dropdown de estado
  const createStatusOptions = async () => {

    // busca los estados en memoria
    let statusList = GLOBAL.screen1.state.statusList
    if (!statusList){
      statusList = await GLOBAL.screen1.getPossibleStatuses()
    }

    await GLOBAL.screen1.loadConfig('Dropdown');
    let drop_down_options = GLOBAL.screen1.state.config_Dropdown;

    let options = statusList.filter(item => drop_down_options.includes(item));

    // crea el diccionario necesario para usar los estados como opciones del dropdown
    let optionsDictList = options.map(option => labelMaker(GLOBAL.screen1.state.states[option]["label"], option))

    // hace un setState para actualizar la lista de opciones del estado
    setOptionLists({...optionLists,statusOptions:optionsDictList})
    //setStatusOptions(optionsDictList)
  }

  const createDropdownOptions = async (listName) => {

    if (!GLOBAL.screen1.state[listName]){
      await GLOBAL.screen1.createList(listName)
    }

    let optionsDictList = []

    if (!("" in Object.values(GLOBAL.screen1.state[listName]))){
      optionsDictList.push(labelMaker("", ""))
    }
    for (var key in GLOBAL.screen1.state[listName]){
      optionsDictList.push(labelMaker(GLOBAL.screen1.state[listName][key], key))
    }
  
    // hace un setState para actualizar la lista de opciones del estado
    //this.setState({...this.state, statusOptions:optionsDictList})
    optionLists[listName]=optionsDictList
    return optionsDictList
  }

  const load = async () => {
    setLoading(true)
    await GLOBAL.screen1.loadConfig('whatsapp_url');
    createStatusOptions();
    GLOBAL.screen1.loadStatuses().then((r) => setStatusObject(r));
    await createDropdownOptions('plan_list')
    const conf = await GLOBAL.screen1.loadConfig('Leads')
    
    
    const rawFields = await GLOBAL.screen1.loadConfig('leads_cstm') || [];
    const old_conf_fields = convertOldConfigToNew(conf) || [];

    setFields([...old_conf_fields,...rawFields]);
    setConfig(conf);


    await getOneitem("Leads", id)
    .then(res => {
      var temp_lead = res
      setLead("attributes" in res ? temp_lead.attributes : temp_lead)
      setForm("attributes" in res ? temp_lead.attributes : temp_lead)

    }).catch((err) => {
      //utils.simpleErrorPopup(err, navigation);
    })
    setLoading(false)

  }

  useEffect(() => {
    load()
  }, [])
  useEffect(() => {
    setForm({...formValues, [config.status]:lead[config.status]})
    console.log("init", formValues)

  }, [statusOptions, lead])

  const convertOldConfigToNew=(oldConfig)=> {
    // Define mapping based on old configuration keys
    const mappings = {
      telefono1: { labelKey: 'leadsFormPhone1', type: 'phone-pad', row: 1, required: true },
      telefono2: { labelKey: 'leadsFormPhone2', type: 'phone-pad', row: 1, required: false },
      email: { labelKey: 'leadsFormEmail', type: 'email-address', row: 2, required: true },
      status: { labelKey: 'leadsFormStatus', type: 'select', options:"statusOptions",row: 3, required: true },
      subestado: { labelKey: 'leadsFormSubstatus', type: 'select', dependsOn:"status" , row: 3, required: false },
      producto: { labelKey: 'leadsFormProduct', type: 'select', options:"plan_list", row: 4, required: false },
      descripcion: { labelKey: 'leadsFormDescription', type: 'big', row: 5, required: false },
      direccion: { labelKey: 'leadsFormHomeAddress', type: 'address', row: 6, required: false },
      provincia: { labelKey: 'leadsFormProvince', type: 'text', row: 7, required: false },
    };

    const newConfig = [];
    newConfig.push({labelKey:"agendaFormTask", type: 'task-button', row: 4 });

    Object.keys(oldConfig).forEach((key) => {
      if (mappings[key]) {
        const field = { key:oldConfig[key], ...mappings[key] };

        // Handle special types like "address" if needed
        if (field.type === 'address') {
          field.fields = {
            addressField: oldConfig.direccion,
            streetNumberField: oldConfig.street_num,
            streetNameField: oldConfig.street_name,
            cityField: oldConfig.city_name,
            regionField: oldConfig.region_name,
            stateField: oldConfig.state_name,
            countryField: oldConfig.country_name,
            latitudeField: oldConfig.latitude,
            longitudeField: oldConfig.longitude,
          };
        }

        newConfig.push(field);
      }
    });

    return newConfig;
  }

  const renderDynamic = () => {
    if (!fields || fields.length === 0) return null;
  
    // Group fields by rows
    const groupedFields = fields.reduce((acc, field) => {
      if (!acc[field.row]) {
        acc[field.row] = [field];
      } else {
        acc[field.row].push(field);
      }
      return acc;
    }, {});
  
    return (
      <div>
        {Object.values(groupedFields).map((rowFields, rowIndex) => (
          <div className="row" key={`row-${rowIndex}`}>
            {rowFields.map((field, fieldIndex) => (
              <React.Fragment key={`${field.key}-${fieldIndex}`}>
                {/*fieldIndex > 0 && <div style={{ width: 10 }} />*/}
                <div className="col">{renderField(field)}</div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    );
  }
  
  
  const renderDependentSelect = (field) => {
    const dependencyValue = formValues[field.dependsOn];
    let options = [];
  
    if (field.dependsOn === "status" && dependencyValue) {
      const statusState = statusObject[dependencyValue];
      if (statusState && statusState.substatus) {
        options = statusState.substatus;
      }
    }
  
    return <Select
      data={options}
      label={field.labelKey?i18n.t(field.labelKey):field.label}
      name={config.subestado}
      value={formValues[field.key]}
      onChange={handleInputChange}
      onFocus={()=>setIsEditing(true)}
    />
  }
  
  const renderField = (field) => {
    const label = field.labelKey?i18n.t(field.labelKey):field.label;
  
    switch (field.type) {
      case "phone-pad":
        return <Input
            type="number"
            label={label}
            name={field.key}
            value={formValues[field.key]}
            onChange={handleInputChange}
            onFocus={()=>setIsEditing(true)}
          />
      case "email-address":
        return <Input
            type="email"
            label={label}
            name={field.key}
            value={formValues[field.key]}
            onChange={handleInputChange}
            onFocus={()=>setIsEditing(true)}
          />
      case "text":
        return <Input
            label={label}
            name={field.key}
            value={formValues[field.key]}
            onChange={handleInputChange}
            onFocus={()=>setIsEditing(true)}
          />
      case "big":
        return <Input
            label={label}
            name={field.key}
            value={formValues[field.key]}
            onChange={handleInputChange}
            onFocus={()=>setIsEditing(true)}
            tall={+true}
          />
      case "select":
        if (field.dependsOn){
          return renderDependentSelect(field);
        }
  
        return <Select
            data={optionLists[field.options]}
            label={label}
            name={field.key}
            value={formValues[field.key]}
            onChange={handleInputChange}
            onFocus={()=>setIsEditing(true)}
          />
      case "task-button":
        return <AddTaskButton label={label} id={id}/>
      case "address":
        const {
          addressField,
          streetNumberField,
          streetNameField,
          cityField,
          regionField,
          stateField,
          countryField,
          latitudeField,
          longitudeField,
        } = field.fields;
  
        return <AddressInput
            label={label}
            addressField={addressField}
            value={formValues[addressField]}
            onChange={handleInputChange}
            onChangeSelect={handleManyChange}
            onFocus={() => setIsEditing(true)}
            streetNumberField={streetNumberField}
            streetNameField={streetNameField}
            cityField={cityField}
            regionField={regionField}
            stateField={stateField}
            countryField={countryField}
            latitudeField={latitudeField}
            longitudeField={longitudeField}
          />
      default:
        return null;
    }
  }
  


  const renderButtons = () => {
    return (
      <div className="one-lead-buttons-div">
        <button
          className="one-lead-button one-lead-button-cancel"
          onClick={() => {setIsEditing(false); setForm(lead);}}
          >

          {i18n.t('leadsFormCancelBotton')}

        </button>
        <button
          className="one-lead-button one-lead-button-accept"
          onClick={() => {console.log(formValues);patchItem("Lead", formValues, id).then(()=>{setIsEditing(false);setLead(formValues)})}}
          >

          {i18n.t('leadsFormSaveBotton')}

        </button>

      </div>

    )
  }

  if (statusObject[formValues[config.status]]){
    if (statusObject[formValues[config.status]]["substatus"].length===0){
      formValues[config.subestado] = '';
    }
    else if (!statusObject[formValues[config.status]]["substatus"].map((i)=>i["value"]).includes(formValues[config.subestado])) formValues[config.subestado] = statusObject[formValues[config.status]]["substatus"][0]["value"]
  }

  if (!config || loading) return <Spinner className="spinner" size={32} speed={1} animating={loading}/>
  return (

    <>
      <nav className="one-lead-top-menu-container">
        <ul className="top-menu">       
          <li>
            <Link className={top_selected === "detail" ? "top-menu-link-selected":"top-menu-link"} to={"/lead/"+id+"/detail"}>
                <h1>{i18n.t("leadsGeneralTitle")}</h1>
            </Link>
          </li>
          <li>
            <Link className={top_selected === "history" ? "top-menu-link-selected":"top-menu-link"} to={"/lead/"+id+"/history"}>
                <h1>{i18n.t("leadsHistoryTitle")}</h1>
            </Link>
          </li>
        </ul>
      </nav>

    {top_selected === "detail" &&
    <div className="one-lead-container">

        <div className="one-lead-title">
            <Title
              name={config.primer_nombre}
              status={lead.status}
              value={formValues[config.primer_nombre]}
              onChange={handleInputChange}
              onFocus={()=>setIsEditing(true)}
              />
              <div className="one-lead-call-buttons-container">
                {!!GLOBAL.screen1.state.config_whatsapp_url&&
                <Link className="one-lead-call-button" to={GLOBAL.screen1.state.config_whatsapp_url+formValues[config.telefono1]}>
                <i id="one-lead-wpp-icon" className="ph-fill ph-whatsapp-logo"></i>
                </Link>}
                <Link className="one-lead-call-button" to={`tel:${formValues[config.telefono1]}`}>
                <i id="one-lead-call-icon" className="ph-fill ph-phone-outgoing"></i>
                </Link>
              </div>
        </div>

        <Separator/>

        <div className="one-lead-inputs">

            {renderDynamic()}

            <div className="row">
            <div className="col">
                {isEditing && renderButtons()}
            </div>
            </div>


            <br/><br/><br/><br/><br/><br/>

        </div>

    </div>
    }
    {top_selected === "history" &&
      <div>
        <Historial id={id}/>
      </div>
    }

    </>

  );

};

export default OneLead;
