import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { Spinner } from "react-activity";
import { Link } from "react-router-dom";


const LocationSearchInput = ({
  addressField,
  streetNumberField,
  streetNameField,
  cityField,
  regionField,
  stateField,
  countryField,
  latitudeField,
  longitudeField,
  style,
  onFocus,
  onChange,
  onChangeSelect,
  value,
}) => {
  //const [address, setAddress] = useState(value || '');

  const mapsButton=(latitude, longitude, address) => {
    return
    if (!address){
      return
    }
  
    let link=`https://www.google.com/maps/`
    if (!latitude||!longitude){
      link+= `search/${encodeURIComponent(address)}`
    }else{
      link+= `dir/${latitude},${longitude}/${encodeURIComponent(address)}`
    }
  
  
    return(
      <div className="maps-button-container">
        <Link className='maps-button-text' to={link} target='_blank'>Ir a Google Maps</Link>
      </div>)
  }

  const handleChange = (address) => {
    //setAddress(address);
    if (onChange) {
      onChange({ target: { name: addressField, value: address } });
    }
  };

  const handleSelect = (address) => {

      geocodeByAddress(address)
      .then((results) => {
        const addressComponents = results[0].address_components;
        const getComponent = (type) => {
          return (
            addressComponents.find((component) =>
              component.types.includes(type)
            )?.long_name || ''
          );
        };
      
      const location = results[0].geometry.location;
        
      onChangeSelect({
        ...streetNumberField?{[streetNumberField]:getComponent('street_number')}:{},
        ...streetNameField?{[streetNameField]:getComponent('route')}:{},
        ...cityField?{[cityField]:getComponent('locality')}:{},
        ...regionField?{[regionField]:getComponent('administrative_area_level_2')}:{},
        ...stateField?{[stateField]:getComponent('administrative_area_level_1')}:{},
        ...countryField?{[countryField]:getComponent('country')}:{},
        ...addressField?{[addressField]:address}:{},
        ...latitudeField?{[latitudeField]:location.lat()}:{},
        ...longitudeField?{[longitudeField]:location.lng()}:{},
      })
      
    })
    .catch((error) => console.error('Error', error));

  };


  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: { country: [] },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='location-search'>
          <div className='row'>
          <input className='address-input-component'
            {...getInputProps({
              onFocus,
              style: { ...style },
            })}
          />
          {mapsButton(null, null, value)}
          </div>
          <div>
            {loading && <Spinner className="spinner" size={32} speed={1} animating={loading} />}
            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                cursor: 'pointer',
                padding: '10px',
              };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, { style })}
                  key={suggestion.placeId}
                >
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
